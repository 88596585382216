.whatsapp-float {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    overflow: hidden;
    background-color: #25d366; /* WhatsApp green */
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.whatsapp-button {
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 60px;
    color: white;
    text-decoration: none;
}

.whatsapp-icon {
    width: 40px;
    height: 40px;
}
